import { Route } from '@/core/router.types'
import type Administration from './Administration'

/*****
 *
 * Administration page routes..
 */

export default [
  {
    menu: { label: 'administration_admin', icon: 'Administration' },
    path: '/administration',
    query: '',
    authorize: ['customer_success', 'customer_manager'],
    component: () => import(/* webpackChunkName: "Administration" */ './Administration'),
    response: (data) => ({
      title: 'Administration',
      description: 'Use the search to start support tasks for Administration.',
      props: data
    })
  } as unknown as Route<typeof Administration>
] as const
