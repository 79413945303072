import '@/locales/i18n'
import { store } from '@/store'
import { styled } from '@mui/system'
import * as Sentry from '@sentry/react'
import { BrowserTracing } from '@sentry/tracing'
import history from 'history/browser'
import { asyncWithLDProvider } from 'launchdarkly-react-client-sdk'
import { createRoot } from 'react-dom/client'
import { Provider } from 'react-redux'
import { App, Login } from './common'
import { config } from './core/config'
import { setupTenant } from './core/tenant'
import { TenantData } from './core/types'
import reportWebVitals from './reportWebVitals'

const rootUrl = new URL(document?.URL)
const tenant = (await setupTenant(rootUrl)) as TenantData

// Render the top-level React component
// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const container = document.getElementById('root')!
const root = createRoot(container)

config.app.env !== 'local' &&
  Sentry.init({
    environment: 'production',
    dsn: 'https://2e9c055f63984d3aac98ed33ee94ddea@o455686.ingest.sentry.io/4504565293514752',
    integrations: [new BrowserTracing()],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0
  })

const BackgroundContainer = styled('div')({
  backgroundColor: '#F8F8FA',
  minHeight: '100vh'
})

interface LaunchDarklyOptions {
  bootstrap?: Record<string, boolean>
  baseUrl?: string
  streaming?: boolean
  sendEvents?: boolean
  evaluationReasons?: boolean
  timeout?: number
}

const initLaunchDarkly = async () => {
  if (config.app.env === 'local') {
    return await asyncWithLDProvider({
      clientSideID: config.launchDarkly.clientSideId,
      flags: {
        'employee-id-config-enabled': true
      },
      options: {
        streaming: config.launchDarkly.streaming
      }
    })
  }

  try {
    return await asyncWithLDProvider({
      clientSideID: config.launchDarkly.clientSideId,
      options: {
        streaming: true,
        sendEvents: true,
        evaluationReasons: true
      }
    })
  } catch {
    return await asyncWithLDProvider({
      clientSideID: config.launchDarkly.clientSideId,
      flags: {
        'employee-id-config-enabled': false
      },
      options: {
        streaming: false,
        sendEvents: false
      }
    })
  }
}

const LDProvider = await initLaunchDarkly()

root.render(
  <Provider store={store}>
    <LDProvider>
      {!tenant.id ? (
        <BackgroundContainer>
          <Login />
        </BackgroundContainer>
      ) : (
        <App history={history} tenant={tenant} />
      )}
    </LDProvider>
  </Provider>
)

// To start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
