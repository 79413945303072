import { Download as DownloadIcon } from '@/icons'
import CloseIcon from '@mui/icons-material/Close'
import { AppBar, Button, Dialog, DialogContent, IconButton, Toolbar, Typography } from '@mui/material'
import { Viewer, Worker } from '@react-pdf-viewer/core'
import '@react-pdf-viewer/core/lib/styles/index.css'
import '@react-pdf-viewer/default-layout/lib/styles/index.css'
import { RenderDownloadProps, getFilePlugin } from '@react-pdf-viewer/get-file'
import { useTranslation } from 'react-i18next'
import { CertificateData } from '../../../types'
import { appBarStyles, downloadButtonStyles, toolBarStyles } from './CertificateViewModalStyles'

type CertificateViewModalProps = {
  open: boolean
  onCloseAction?: () => void
  certificate: CertificateData
  userFullName: string
}

export const CertificateViewModal = ({
  userFullName,
  certificate,
  open,
  onCloseAction
}: CertificateViewModalProps): JSX.Element => {
  const { t } = useTranslation('general')
  const getFilePluginInstance = getFilePlugin()
  const { Download } = getFilePluginInstance

  return (
    <Worker workerUrl="/public/pdf.worker.min.js">
      <AppBar position="fixed" sx={{ ...appBarStyles, display: open ? 'block' : 'none' }}>
        <Toolbar sx={toolBarStyles}>
          <Typography variant="h6" color="#212121" component="div" fontSize={20} fontWeight={600}>
            Certificate_{certificate.course_title}_{userFullName}_{certificate.obtained_on}
          </Typography>
          <IconButton onClick={onCloseAction}>
            <CloseIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <Dialog
        open={open}
        onClose={onCloseAction}
        maxWidth="md"
        fullWidth
        aria-labelledby="responsive-dialog-title"
        sx={{
          '& .MuiPaper-root': {
            backgroundColor: 'transparent',
            boxShadow: 'none'
          },
          '.rpv-core__inner-page': {
            backgroundColor: 'transparent'
          }
        }}>
        <DialogContent sx={{ padding: 0, width: 'auto' }}>
          <Viewer defaultScale={1} fileUrl={certificate.url} plugins={[getFilePluginInstance]} />
          <Download>
            {(props: RenderDownloadProps) => (
              <Button color="secondary" sx={downloadButtonStyles} onClick={props.onClick}>
                <DownloadIcon htmlColor="#001FB2" sx={{ paddingTop: '3px' }} />
                <Typography variant="h6" color="#001FB2" component="div" fontSize={16} fontWeight={600}>
                  {t('cert_download')}
                </Typography>
              </Button>
            )}
          </Download>
        </DialogContent>
      </Dialog>
    </Worker>
  )
}
