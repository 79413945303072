import i18n from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import Backend from 'i18next-locize-backend'
import { locizePlugin } from 'locize'
import { initReactI18next } from 'react-i18next'

const projectId = process.env.LOCIZE_PROJECT_ID
const apiKey = process.env.LOCIZE_API_KEY

if (!projectId) {
  throw new Error('Missing Locize project ID.')
}

if (!apiKey) {
  throw new Error('Missing Locize API key.')
}

export const locizeOptions = {
  projectId,
  apiKey,
  referenceLng: 'en'
}

i18n
  .use(locizePlugin)
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    debug: false,
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false
    },
    backend: locizeOptions,
    locizeLastUsed: locizeOptions
  })

export default i18n
