import { Route } from '@/core/router.types'
import type Certificates from './Certificates'

/**
 * Certificates page.
 */
export default [
  {
    menu: { label: 'cert_myCertificates', icon: 'CertificateIcon' },
    path: '/my/certificates',
    query: '',
    authorize: ['customer_success', 'participant', 'customer_manager'],
    component: () => import(/* webpackChunkName: "Certificates" */ /* webpackMode: "eager" */ '../routes/Certificates'),
    response: (data) => ({
      title: 'Certificate Overview',
      description: 'Use the search to start support tasks for certificates.',
      props: data
    })
  } as unknown as Route<typeof Certificates>
] as const
