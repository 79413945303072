import { Button, ButtonProps } from '@mui/material'
import { SystemStyleObject } from '@mui/system'
import React from 'react'
import CustomPalette from '../../../theme/CustomPalette'

interface CustomButtonProps extends ButtonProps {
  children: React.ReactNode
  height?: string
  width?: string
  styles?: SystemStyleObject
  isMenuOpen?: boolean
  onClick?: (event: any) => void
}

export const CancelButtonStyle = {
  backgroundColor: 'transparent',
  color: CustomPalette.PilotBlue,
  fontSize: '16px',
  fontWeight: 400,
  '&:hover': {
    backgroundColor: 'transparent'
  }
}

const CustomButton: React.FC<CustomButtonProps> = ({
  children,
  height,
  width,
  styles,
  isMenuOpen,
  onClick,
  ...props
}) => (
  <Button
    data-testid="custom-button"
    onClick={onClick}
    type="submit"
    sx={{
      ...styles,
      pl: '24px',
      pr: '24px',
      height: height ? height : '40px',
      width: width && width,
      backgroundColor: props.disabled ? CustomPalette.SecondaryNightBlue50 : CustomPalette.PilotBlue,
      color: props.disabled ? CustomPalette.DisableGray : '#fff',
      border: '1px solid transparent',
      borderRadius: !isMenuOpen ? '20px' : isMenuOpen ? '20px 20px 0px 0px' : '30px',
      transition: '0.5s ease',
      '&:hover': {
        backgroundColor: CustomPalette.SecondaryCloud,
        color: CustomPalette.PilotBlue,
        borderColor: CustomPalette.PilotBlue,
        transition: ' 0.5s ease'
      },
      '&:disabled': { backgroundColor: CustomPalette.SecondaryNightBlue50, color: CustomPalette.SecondaryNightBlue400 },
      textTransform: 'none',
      fontSize: 16,
      fontWeight: '450'
    }}
    {...props}>
    {children}
  </Button>
)

export default CustomButton
