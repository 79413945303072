import { Route } from '@/core/router.types'
import AddParticipantPage from './Details/features/ManageParticipants/components/AddParticipantPage/AddParticipantPage'
import type Participants from './Participants'
import EmployeeIDAuthentication from './Details/features/Manage/components/EmployeeIDAuthentication/EmployeeIDAuthentication'

/*****
 *
 * Participants page routes..
 */

export default [
  {
    menu: { label: 'participants', icon: 'ParticipantsIcon' },
    path: '/participants',
    query: '',
    authorize: ['customer_success', 'customer_manager'],
    component: () => import('./Participants'),
    response: (data: any) => ({
      title: 'Participants',
      description: 'List of participants in the system.',
      props: data
    })
  } as unknown as Route<typeof Participants>,
  {
    path: '/participants/add-participant',
    query: '',
    authorize: ['customer_success', 'customer_manager'],
    component: () => import('./Details/features/ManageParticipants/components/AddParticipantPage/AddParticipantPage'),
    response: (data: any) => ({
      title: 'Add Participant',
      description: '',
      props: data
    })
  } as unknown as Route<typeof AddParticipantPage>,
  {
    path: '/participants/employee-id-authentication',
    query: '',
    authorize: ['customer_success', 'customer_manager'],
    component: () => import('./Details/features/Manage/components/EmployeeIDAuthentication/EmployeeIDAuthentication'),
    response: (data: any) => ({
      title: 'Employee ID Authentication',
      description: '',
      props: data
    })
  } as unknown as Route<typeof EmployeeIDAuthentication>
] as const
