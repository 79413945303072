import { useState } from 'react'

// material-ui
import { CheckIcon, Download as DownloadIcon, VisibilityIcon } from '@/icons'
import CloseIcon from '@mui/icons-material/Close'
import { Alert, Box, Button, CardActions, CardContent, IconButton, Typography } from '@mui/material'

// project import
import { CardGridStack } from '@/common/components/CardGridStack'
import { CertificateData } from '../../../types'
import { CertificateViewModal } from '../Modals/CertificateViewModal'
import {
  alertStyles,
  certDownloadButtonStyles,
  certViewButtonStyles,
  certificateTitleStyles,
  obtainedOnTitleStyles,
  trainingInternalNameStyles
} from './CardListStyles'

// assets
import { Certificate } from '@/icons/Certificate'
import FontFaceLoader from '@/theme/CustomFont'
import { useTranslation } from 'react-i18next'

type cardListProps = {
  userFullName: string
  list: CertificateData[]
}

export const CardList = ({ userFullName, list }: cardListProps): JSX.Element => {
  const { t } = useTranslation('general')
  const [openedCertificateId, setOpenedCertificateId] = useState<string | null>(null)
  const [downloadStatus, setDownloadStatus] = useState<string | null>(null)
  const handleOpenCertificateView = (id: string) => {
    setOpenedCertificateId(id)
  }

  const handleDownloadCertificate = (certificate: CertificateData) => {
    fetch(certificate.url)
      .then((response) => response.blob())
      .then((blob) => {
        const url = window.URL.createObjectURL(blob)
        const link = document.createElement('a')
        const fileName = `Certificates_lawpilots E-Learning_${userFullName}_${certificate.obtained_on}.pdf`
        link.href = url
        link.setAttribute('download', fileName)
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
        setDownloadStatus('success')
      })
      .catch((error: Error) => {
        setDownloadStatus('error')
      })
  }

  return (
    <>
      <Box>
        <FontFaceLoader />
        <CardGridStack
          items={list?.slice(0).map((certificate: CertificateData) => (
            <>
              <Box sx={{ ml: 1, mr: 1 }}>
                {certificate && (
                  <CertificateViewModal
                    open={certificate.id === openedCertificateId}
                    onCloseAction={() => setOpenedCertificateId(null)}
                    certificate={certificate}
                    userFullName={userFullName}
                  />
                )}
                <CardContent>
                  <Box>
                    <Certificate sx={{ mb: -1 }} />
                    <Box sx={{ display: 'inline-block' }}>
                      <Typography sx={certificateTitleStyles} color="text.secondary" gutterBottom>
                        {t('cert_certificate')}
                      </Typography>
                    </Box>
                  </Box>
                  <Typography sx={trainingInternalNameStyles}>{certificate.course_title}</Typography>
                  <Typography sx={obtainedOnTitleStyles}>{certificate.obtained_on}</Typography>
                </CardContent>
                <CardActions>
                  <Box sx={{ ml: 1 }}>
                    <Button
                      sx={certViewButtonStyles}
                      size="small"
                      color="inherit"
                      onClick={() => handleOpenCertificateView(certificate.id)}
                      startIcon={<VisibilityIcon />}>
                      {t('cert_view')}
                    </Button>
                    <Button
                      sx={certDownloadButtonStyles}
                      size="small"
                      color="inherit"
                      startIcon={<DownloadIcon sx={{ marginRight: 0 }} />}
                      onClick={() => handleDownloadCertificate(certificate)}>
                      {t('cert_download')}
                    </Button>
                  </Box>
                </CardActions>
              </Box>
            </>
          ))}
          xs={12}
          sm={12}
          md={6}
          lg={6}
          xl={3}
        />
        {downloadStatus && (
          <Alert
            color={downloadStatus === 'success' ? 'success' : 'error'}
            icon={<CheckIcon strokeColor={downloadStatus === 'error' ? '#D32F2F' : '#4D824F'} />}
            action={
              <IconButton
                aria-label="close"
                size="small"
                onClick={() => {
                  setDownloadStatus(null)
                }}>
                <CloseIcon fontSize="inherit" color="inherit" />
              </IconButton>
            }
            sx={alertStyles}>
            {downloadStatus === 'success' ? t('cert_download_success') : t('cert_download_error')}
          </Alert>
        )}
      </Box>
    </>
  )
}
