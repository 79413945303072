import { Route } from '@/core/router.types'
import type HomeManagement from '../components/HomeManagement'

/*****
 *
 * Home page routes.
 */

export default [
  {
    menu: { label: 'Home', icon: 'Home' },
    path: '/home',
    query: '',
    showDivider: true,
    dividerStyles: {
      mt: 0,
      mb: 4,
      borderBottom: '1px solid #C4C4E5'
    },
    header: 'learningManagement',
    headerStyles: {
      color: '#151830',
      marginLeft: '26px',
      marginBottom: '12px'
    },
    authorize: ['customer_success', 'customer_manager'],
    component: () => import('../components/HomeManagement'),
    response: (data: any) => ({
      title: 'Home Management',
      description: 'Home Management page.',
      props: data
    })
  } as unknown as Route<typeof HomeManagement>
] as const
