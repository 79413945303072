import { tenantApi as api } from './api'
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    usersGetCurrentUserUserGet: build.query<UsersGetCurrentUserUserGetApiResponse, UsersGetCurrentUserUserGetApiArg>({
      query: () => ({ url: `/user` })
    }),
    usersGetUsersForTenantTenantsTenantIdUsersGet: build.query<
      UsersGetUsersForTenantTenantsTenantIdUsersGetApiResponse,
      UsersGetUsersForTenantTenantsTenantIdUsersGetApiArg
    >({
      query: (queryArg) => ({
        url: `/tenants/${queryArg.tenantId}/users`,
        params: {
          roles: queryArg.roles,
          locations: queryArg.locations,
          departments: queryArg.departments,
          positions: queryArg.positions,
          training_ids: queryArg.trainingIds,
          add_trainings: queryArg.addTrainings,
          query: queryArg.query,
          page: queryArg.page,
          size: queryArg.size
        }
      })
    }),
    usersCreateUserTenantsTenantIdUsersPost: build.mutation<
      UsersCreateUserTenantsTenantIdUsersPostApiResponse,
      UsersCreateUserTenantsTenantIdUsersPostApiArg
    >({
      query: (queryArg) => ({
        url: `/tenants/${queryArg.tenantId}/users`,
        method: 'POST',
        body: queryArg.createUserDto
      })
    }),
    usersSearchUsersUsersGet: build.query<UsersSearchUsersUsersGetApiResponse, UsersSearchUsersUsersGetApiArg>({
      query: (queryArg) => ({
        url: `/users`,
        params: { roles: queryArg.roles, query: queryArg.query, add_trainings: queryArg.addTrainings }
      })
    }),
    usersGetUserTenantsTenantIdUsersUserIdGet: build.query<
      UsersGetUserTenantsTenantIdUsersUserIdGetApiResponse,
      UsersGetUserTenantsTenantIdUsersUserIdGetApiArg
    >({
      query: (queryArg) => ({
        url: `/tenants/${queryArg.tenantId}/users/${queryArg.userId}`,
        params: { add_trainings: queryArg.addTrainings }
      })
    }),
    usersUpdateUserTenantsTenantIdUsersUserIdPut: build.mutation<
      UsersUpdateUserTenantsTenantIdUsersUserIdPutApiResponse,
      UsersUpdateUserTenantsTenantIdUsersUserIdPutApiArg
    >({
      query: (queryArg) => ({
        url: `/tenants/${queryArg.tenantId}/users/${queryArg.userId}`,
        method: 'PUT',
        body: queryArg.updateUserDto
      })
    }),
    usersDeleteUserTenantsTenantIdUsersUserIdDelete: build.mutation<
      UsersDeleteUserTenantsTenantIdUsersUserIdDeleteApiResponse,
      UsersDeleteUserTenantsTenantIdUsersUserIdDeleteApiArg
    >({
      query: (queryArg) => ({ url: `/tenants/${queryArg.tenantId}/users/${queryArg.userId}`, method: 'DELETE' })
    }),
    usersUpdateUserSettingsTenantsTenantIdUsersUserIdSettingsPatch: build.mutation<
      UsersUpdateUserSettingsTenantsTenantIdUsersUserIdSettingsPatchApiResponse,
      UsersUpdateUserSettingsTenantsTenantIdUsersUserIdSettingsPatchApiArg
    >({
      query: (queryArg) => ({
        url: `/tenants/${queryArg.tenantId}/users/${queryArg.userId}/settings`,
        method: 'PATCH',
        body: queryArg.updateUserSettingsDto
      })
    }),
    usersArchiveUsersTenantsTenantIdArchiveUsersPut: build.mutation<
      UsersArchiveUsersTenantsTenantIdArchiveUsersPutApiResponse,
      UsersArchiveUsersTenantsTenantIdArchiveUsersPutApiArg
    >({
      query: (queryArg) => ({
        url: `/tenants/${queryArg.tenantId}/archive/users`,
        method: 'PUT',
        body: queryArg.userData
      })
    }),
    usersInviteUsersTenantsTenantIdUsersInvitePost: build.mutation<
      UsersInviteUsersTenantsTenantIdUsersInvitePostApiResponse,
      UsersInviteUsersTenantsTenantIdUsersInvitePostApiArg
    >({
      query: (queryArg) => ({ url: `/tenants/${queryArg.tenantId}/users/invite`, method: 'POST', body: queryArg.ids })
    }),
    usersImportUsersTenantsTenantIdImportUsersPost: build.mutation<
      UsersImportUsersTenantsTenantIdImportUsersPostApiResponse,
      UsersImportUsersTenantsTenantIdImportUsersPostApiArg
    >({
      query: (queryArg) => ({
        url: `/tenants/${queryArg.tenantId}/import/users`,
        method: 'POST',
        body: queryArg.bodyUsersImportUsersTenantsTenantIdImportUsersPost
      })
    }),
    usersUpdateUserStatusTenantsTenantIdUsersUserIdUpdateUserStatusPatch: build.mutation<
      UsersUpdateUserStatusTenantsTenantIdUsersUserIdUpdateUserStatusPatchApiResponse,
      UsersUpdateUserStatusTenantsTenantIdUsersUserIdUpdateUserStatusPatchApiArg
    >({
      query: (queryArg) => ({
        url: `/tenants/${queryArg.tenantId}/users/${queryArg.userId}/update-user-status`,
        method: 'PATCH',
        body: queryArg.updateUserStatusDto
      })
    }),
    usersGetUserFiltersTenantsTenantIdUsersViewFiltersGet: build.query<
      UsersGetUserFiltersTenantsTenantIdUsersViewFiltersGetApiResponse,
      UsersGetUserFiltersTenantsTenantIdUsersViewFiltersGetApiArg
    >({
      query: (queryArg) => ({
        url: `/tenants/${queryArg.tenantId}/users-view/filters`,
        params: { type: queryArg['type'], search: queryArg.search, page: queryArg.page, size: queryArg.size }
      })
    }),
    trainingsGetCurrentUserTrainingParticipationsTenantsTenantIdMeTrainingParticipationsGet: build.query<
      TrainingsGetCurrentUserTrainingParticipationsTenantsTenantIdMeTrainingParticipationsGetApiResponse,
      TrainingsGetCurrentUserTrainingParticipationsTenantsTenantIdMeTrainingParticipationsGetApiArg
    >({
      query: (queryArg) => ({ url: `/tenants/${queryArg.tenantId}/me/training_participations` })
    }),
    trainingsAssignUsersToTrainingsTenantsTenantIdTrainingUsersPost: build.mutation<
      TrainingsAssignUsersToTrainingsTenantsTenantIdTrainingUsersPostApiResponse,
      TrainingsAssignUsersToTrainingsTenantsTenantIdTrainingUsersPostApiArg
    >({
      query: (queryArg) => ({
        url: `/tenants/${queryArg.tenantId}/training_users`,
        method: 'POST',
        body: queryArg.payloads,
        params: { send_invite: queryArg.sendInvite }
      })
    }),
    trainingsGetTrainingUsersTenantsTenantIdTrainingsTrainingCodeUsersGet: build.query<
      TrainingsGetTrainingUsersTenantsTenantIdTrainingsTrainingCodeUsersGetApiResponse,
      TrainingsGetTrainingUsersTenantsTenantIdTrainingsTrainingCodeUsersGetApiArg
    >({
      query: (queryArg) => ({
        url: `/tenants/${queryArg.tenantId}/trainings/${queryArg.trainingCode}/users`,
        params: { only_completed: queryArg.onlyCompleted, training_cycle_id: queryArg.trainingCycleId }
      })
    }),
    trainingsArchiveTrainingUsersTenantsTenantIdTrainingsTrainingCodeUsersArchivePut: build.mutation<
      TrainingsArchiveTrainingUsersTenantsTenantIdTrainingsTrainingCodeUsersArchivePutApiResponse,
      TrainingsArchiveTrainingUsersTenantsTenantIdTrainingsTrainingCodeUsersArchivePutApiArg
    >({
      query: (queryArg) => ({
        url: `/tenants/${queryArg.tenantId}/trainings/${queryArg.trainingCode}/users/archive`,
        method: 'PUT'
      })
    }),
    trainingsUnassignTrainingUserTenantsTenantIdTrainingsTrainingCodeUsersUnassignPut: build.mutation<
      TrainingsUnassignTrainingUserTenantsTenantIdTrainingsTrainingCodeUsersUnassignPutApiResponse,
      TrainingsUnassignTrainingUserTenantsTenantIdTrainingsTrainingCodeUsersUnassignPutApiArg
    >({
      query: (queryArg) => ({
        url: `/tenants/${queryArg.tenantId}/trainings/${queryArg.trainingCode}/users/unassign`,
        method: 'PUT',
        body: queryArg.unassignTrainingUserInDto
      })
    }),
    trainingsBulkUnassignTrainingUsersTenantsTenantIdTrainingsTrainingCodeUsersBulkUnassignPut: build.mutation<
      TrainingsBulkUnassignTrainingUsersTenantsTenantIdTrainingsTrainingCodeUsersBulkUnassignPutApiResponse,
      TrainingsBulkUnassignTrainingUsersTenantsTenantIdTrainingsTrainingCodeUsersBulkUnassignPutApiArg
    >({
      query: (queryArg) => ({
        url: `/tenants/${queryArg.tenantId}/trainings/${queryArg.trainingCode}/users/bulk-unassign`,
        method: 'PUT',
        body: queryArg.bulkUnassignTrainingUsersInDto
      })
    }),
    usersBulkUnassignTrainingsTenantsTenantIdBulkUnassignUsersPut: build.mutation<
      UsersBulkUnassignTrainingsTenantsTenantIdBulkUnassignUsersPutApiResponse,
      UsersBulkUnassignTrainingsTenantsTenantIdBulkUnassignUsersPutApiArg
    >({
      query: (queryArg) => ({
        url: `/tenants/${queryArg.tenantId}/bulk-unassign/users`,
        method: 'PUT',
        body: queryArg.userData
      })
    }),
    emailsSendTrainingEmailsToCurrentUserTenantsTenantIdTrainingsTrainingCodeUsersMeEmailsPost: build.mutation<
      EmailsSendTrainingEmailsToCurrentUserTenantsTenantIdTrainingsTrainingCodeUsersMeEmailsPostApiResponse,
      EmailsSendTrainingEmailsToCurrentUserTenantsTenantIdTrainingsTrainingCodeUsersMeEmailsPostApiArg
    >({
      query: (queryArg) => ({
        url: `/tenants/${queryArg.tenantId}/trainings/${queryArg.trainingCode}/users/me/emails`,
        method: 'POST',
        body: queryArg.emailDto
      })
    }),
    certificatesGetCurrentUserCertificatesTenantsTenantIdMeCertificatesGet: build.query<
      CertificatesGetCurrentUserCertificatesTenantsTenantIdMeCertificatesGetApiResponse,
      CertificatesGetCurrentUserCertificatesTenantsTenantIdMeCertificatesGetApiArg
    >({
      query: (queryArg) => ({ url: `/tenants/${queryArg.tenantId}/me/certificates` })
    }),
    getManagedUsersManagedUsersGet: build.query<
      GetManagedUsersManagedUsersGetApiResponse,
      GetManagedUsersManagedUsersGetApiArg
    >({
      query: () => ({ url: `/managed/users` })
    })
  }),
  overrideExisting: true
})
export { injectedRtkApi as enhancedApi }
export type UsersGetCurrentUserUserGetApiResponse = /** status 200 Successful Response */ GetCurrentUserOutDto
export type UsersGetCurrentUserUserGetApiArg = void
export type UsersGetUsersForTenantTenantsTenantIdUsersGetApiResponse =
  /** status 200 Successful Response */
  PageUserWithTrainingsDto | PageUserDto | UserWithTrainingsDto[] | UserDto[]
export type UsersGetUsersForTenantTenantsTenantIdUsersGetApiArg = {
  tenantId: string
  /** The required roles for the queried users, passed as an array of role names. Returned users will match all given roles. */
  roles?: string[]
  /** The locations to filter the queried users. */
  locations?: string[]
  /** The departments to filter the queried users. */
  departments?: string[]
  /** The positions to filter the queried users. */
  positions?: string[]
  /** The trainings to filter the queried users. */
  trainingIds?: string[]
  /** Toggles adding trainings field to the returned response */
  addTrainings?: boolean
  /** A search string to filter returned users */
  query?: string
  page?: number
  size?: number
}
export type UsersCreateUserTenantsTenantIdUsersPostApiResponse = /** status 201 Successful Response */ UserDto
export type UsersCreateUserTenantsTenantIdUsersPostApiArg = {
  tenantId: string
  createUserDto: CreateUserDto
}
export type UsersSearchUsersUsersGetApiResponse =
  /** status 200 Successful Response */
  UserWithTrainingsDto[] | UserDto[]
export type UsersSearchUsersUsersGetApiArg = {
  /** The required roles for the queried users, passed as string with comma-separated role names. Returned users will match all given roles. */
  roles?: string
  /** A search string to filter returned users */
  query?: string
  /** Toggles adding trainings field to the returned response */
  addTrainings?: boolean
}
export type UsersGetUserTenantsTenantIdUsersUserIdGetApiResponse =
  /** status 200 Successful Response */
  UserWithTrainingsDto | UserDto
export type UsersGetUserTenantsTenantIdUsersUserIdGetApiArg = {
  tenantId: string
  userId: string
  /** Toggles adding trainings field to the returned response */
  addTrainings?: boolean
}
export type UsersUpdateUserTenantsTenantIdUsersUserIdPutApiResponse = /** status 200 Successful Response */ UserDto
export type UsersUpdateUserTenantsTenantIdUsersUserIdPutApiArg = {
  tenantId: string
  userId: string
  updateUserDto: UpdateUserDto
}
export type UsersDeleteUserTenantsTenantIdUsersUserIdDeleteApiResponse = /** status 204 Successful Response */ void
export type UsersDeleteUserTenantsTenantIdUsersUserIdDeleteApiArg = {
  tenantId: string
  userId: string
}
export type UsersUpdateUserSettingsTenantsTenantIdUsersUserIdSettingsPatchApiResponse =
  /** status 200 Successful Response */ UserDto
export type UsersUpdateUserSettingsTenantsTenantIdUsersUserIdSettingsPatchApiArg = {
  tenantId: string
  userId: string
  updateUserSettingsDto: UpdateUserSettingsDto
}
export type UsersArchiveUsersTenantsTenantIdArchiveUsersPutApiResponse = /** status 200 Successful Response */ void
export type UsersArchiveUsersTenantsTenantIdArchiveUsersPutApiArg = {
  tenantId: string
  userData: {
    [key: string]: string[]
  }
}
export type UsersInviteUsersTenantsTenantIdUsersInvitePostApiResponse = /** status 200 Successful Response */ {
  [key: string]: string
}
export type UsersInviteUsersTenantsTenantIdUsersInvitePostApiArg = {
  tenantId: string
  ids: string[]
}
export type UsersImportUsersTenantsTenantIdImportUsersPostApiResponse =
  /** status 200 Successful Response */ ImportUserReportDto
export type UsersImportUsersTenantsTenantIdImportUsersPostApiArg = {
  tenantId: string
  bodyUsersImportUsersTenantsTenantIdImportUsersPost: BodyUsersImportUsersTenantsTenantIdImportUsersPost
}
export type UsersUpdateUserStatusTenantsTenantIdUsersUserIdUpdateUserStatusPatchApiResponse =
  /** status 200 Successful Response */ boolean
export type UsersUpdateUserStatusTenantsTenantIdUsersUserIdUpdateUserStatusPatchApiArg = {
  userId: string
  tenantId: string
  updateUserStatusDto: UpdateUserStatusDto
}
export type UsersGetUserFiltersTenantsTenantIdUsersViewFiltersGetApiResponse =
  /** status 200 Successful Response */
  PageUserFilterModelDto | Page
export type UsersGetUserFiltersTenantsTenantIdUsersViewFiltersGetApiArg = {
  tenantId: string
  /** Type of filter to apply */
  type: FilterType
  /** Search string to filter results */
  search?: string
  page?: number
  size?: number
}
export type TrainingsGetCurrentUserTrainingParticipationsTenantsTenantIdMeTrainingParticipationsGetApiResponse =
  /** status 200 Successful Response */ CurrentUserTrainingParticipationDto[]
export type TrainingsGetCurrentUserTrainingParticipationsTenantsTenantIdMeTrainingParticipationsGetApiArg = {
  tenantId: string
}
export type TrainingsAssignUsersToTrainingsTenantsTenantIdTrainingUsersPostApiResponse =
  /** status 200 Successful Response */ GroupedTrainingUsersDto[]
export type TrainingsAssignUsersToTrainingsTenantsTenantIdTrainingUsersPostApiArg = {
  tenantId: string
  /** Toggles sending training invitation emails */
  sendInvite?: boolean
  payloads: CreateTrainingUsersDto[]
}
export type TrainingsGetTrainingUsersTenantsTenantIdTrainingsTrainingCodeUsersGetApiResponse =
  /** status 200 Successful Response */ TrainingUserDto[]
export type TrainingsGetTrainingUsersTenantsTenantIdTrainingsTrainingCodeUsersGetApiArg = {
  tenantId: string
  trainingCode: string
  onlyCompleted?: boolean
  trainingCycleId?: string
}
export type TrainingsArchiveTrainingUsersTenantsTenantIdTrainingsTrainingCodeUsersArchivePutApiResponse =
  /** status 200 Successful Response */ ArchiveTrainingUserDto[]
export type TrainingsArchiveTrainingUsersTenantsTenantIdTrainingsTrainingCodeUsersArchivePutApiArg = {
  tenantId: string
  trainingCode: string
}
export type TrainingsUnassignTrainingUserTenantsTenantIdTrainingsTrainingCodeUsersUnassignPutApiResponse =
  /** status 200 Successful Response */ UnassignTrainingUserOutDto
export type TrainingsUnassignTrainingUserTenantsTenantIdTrainingsTrainingCodeUsersUnassignPutApiArg = {
  tenantId: string
  trainingCode: string
  unassignTrainingUserInDto: UnassignTrainingUserInDto
}
export type TrainingsBulkUnassignTrainingUsersTenantsTenantIdTrainingsTrainingCodeUsersBulkUnassignPutApiResponse =
  /** status 200 Successful Response */ BulkUnassignTrainingUsersOutDto
export type TrainingsBulkUnassignTrainingUsersTenantsTenantIdTrainingsTrainingCodeUsersBulkUnassignPutApiArg = {
  tenantId: string
  trainingCode: string
  bulkUnassignTrainingUsersInDto: BulkUnassignTrainingUsersInDto
}
export type UsersBulkUnassignTrainingsTenantsTenantIdBulkUnassignUsersPutApiResponse =
  /** status 200 Successful Response */ BulkUnassignUserResponseDto
export type UsersBulkUnassignTrainingsTenantsTenantIdBulkUnassignUsersPutApiArg = {
  tenantId: string
  userData: {
    [key: string]: string[]
  }
}
export type EmailsSendTrainingEmailsToCurrentUserTenantsTenantIdTrainingsTrainingCodeUsersMeEmailsPostApiResponse =
  /** status 200 Successful Response */ any
export type EmailsSendTrainingEmailsToCurrentUserTenantsTenantIdTrainingsTrainingCodeUsersMeEmailsPostApiArg = {
  tenantId: string
  trainingCode: string
  emailDto: EmailDto
}
export type CertificatesGetCurrentUserCertificatesTenantsTenantIdMeCertificatesGetApiResponse =
  /** status 200 Successful Response */ CertificateDto[]
export type CertificatesGetCurrentUserCertificatesTenantsTenantIdMeCertificatesGetApiArg = {
  tenantId: string
}
export type GetManagedUsersManagedUsersGetApiResponse = /** status 200 Successful Response */ UserDto[]
export type GetManagedUsersManagedUsersGetApiArg = void
export type GetCurrentUserTokenOutDto = {
  family_name: string
  given_name: string
  email: string
}
export type GetCurrentUserOutDto = {
  user_id: string
  username: string
  tenant_id: string
  group: string
  roles: string[]
  token: GetCurrentUserTokenOutDto
}
export type Detail = {
  detail: string
}
export type ValidUserRoles = 'lawpilots_admin' | 'customer_success' | 'customer_manager' | 'participant'
export type TrainingStatus = 'active' | 'inactive'
export type TrainingReminderType = 'automated' | 'never'
export type TrainingReminderModel = {
  type?: TrainingReminderType
  schedule_in_days?: number
  last_sent_at?: string
}
export type TrainingDto = {
  id: string
  tenant_id: string
  course_id: string
  subscription_id: string
  code: string
  internal_name: string
  course_title?: string
  start_date: string
  end_date?: string
  due_date_in_weeks: number
  recurring_interval_in_months: number
  status: TrainingStatus
  reminder: TrainingReminderModel
  duration_in_minutes: number
  created_at?: string
  updated_at?: string
  archived_at?: string
}
export type UserWithTrainingsDto = {
  first_name: string
  last_name: string
  id: string
  email: string
  email_verified?: boolean
  updated_at: string
  invited_at?: string
  department?: string
  location?: string
  employee_id?: string
  position?: string
  language_tag: string
  roles: ValidUserRoles[]
  trainings: TrainingDto[]
}
export type PageUserWithTrainingsDto = {
  items: UserWithTrainingsDto[]
  total?: number
  page?: number
  size?: number
  pages?: number
}
export type UserDto = {
  first_name: string
  last_name: string
  id: string
  email: string
  email_verified?: boolean
  updated_at: string
  invited_at?: string
  department?: string
  location?: string
  employee_id?: string
  position?: string
  language_tag: string
  roles: ValidUserRoles[]
}
export type PageUserDto = {
  items: UserDto[]
  total?: number
  page?: number
  size?: number
  pages?: number
}
export type ValidationError = {
  loc: (string | number)[]
  msg: string
  type: string
}
export type HttpValidationError = {
  detail?: ValidationError[]
}
export type ValidCreateOrUpdateUserRoles = 'customer_manager' | 'participant'
export type CreateUserDto = {
  first_name: string
  last_name: string
  email: string
  department?: string
  location?: string
  employee_id?: string
  position?: string
  language_tag?: string
  roles: ValidCreateOrUpdateUserRoles[]
  email_verified?: boolean
}
export type UpdateUserDto = {
  department?: string
  location?: string
  employee_id?: string
  position?: string
  language_tag?: string
  roles?: ValidCreateOrUpdateUserRoles[]
  first_name?: string
  last_name?: string
}
export type UpdateUserSettingsDto = {
  language_tag?: string
}
export type UserImportEvent = 'UserRecordCreated' | 'UserRecordUpdated' | 'UserRecordUnchanged' | 'UserRecordFailed'
export type CsvRowReportDto = {
  row: number
  import_event: UserImportEvent
  user: UserDto
}
export type CsvErrorCode =
  | 'EMPTY_CSV'
  | 'PARSING_ERROR'
  | 'FILE_NOT_FOUND'
  | 'UNEXPECTED_ERROR'
  | 'MISSING_REQUIRED_FIELD'
  | 'MISSING_HEADER'
  | 'INVALID_FIELD'
  | 'INVALID_FILE_TYPE'
  | 'FILE_TOO_LARGE'
  | 'USER_ALREADY_ARCHIVED'
export type CsvRowError = {
  row: number
  column?: string
  import_event: UserImportEvent
  code: CsvErrorCode
  reason?: string
}
export type ImportUserReportDto = {
  total_no_of_rows?: number
  no_of_successful_rows?: number
  no_of_failed_rows?: number
  created_rows?: CsvRowReportDto[]
  updated_rows?: CsvRowReportDto[]
  unchanged_rows?: CsvRowReportDto[]
  errors?: CsvRowError[]
  grouped_errors?: {
    [key: string]: CsvRowError[]
  }
}
export type BodyUsersImportUsersTenantsTenantIdImportUsersPost = {
  delimiter?: string
  header_mapping?: string
  encoding?: string
  users_csv: Blob
}
export type UpdateUserStatusDto = {
  email_verified: boolean
}
export type UserFilterModelDto = {
  name: string
  value: string
}
export type PageUserFilterModelDto = {
  items: UserFilterModelDto[]
  total?: number
  page?: number
  size?: number
  pages?: number
}
export type Page = {
  items: any[]
  total?: number
  page?: number
  size?: number
  pages?: number
}
export type FilterType = 'department' | 'role' | 'course' | 'position' | 'location'
export type TrainingParticipationStatus = 'invited' | 'in_progress' | 'completed'
export type CurrentUserTrainingParticipationDto = {
  id: string
  training_code: string
  training_internal_name: string
  course_title: string
  training_start_date: string
  training_end_date?: string
  training_recurring_interval: number
  training_description: string
  training_status?: TrainingParticipationStatus
  repeat: string
  training_user_id: string
  training_cycle_id: string
  course_id: string
  course_duration_in_minutes: number
  courseplay_url?: string
}
export type TrainingUserDto = {
  id: string
  tenant_id: string
  training_id: string
  user_id: string
  completed_on?: string
  created_at?: string
  updated_at?: string
  archived_at?: string
}
export type GroupedTrainingUsersDto = {
  tenant_id: string
  training_id: string
  users: (TrainingUserDto | string)[]
  errors?: string[]
  is_successful: boolean
}
export type CreateTrainingUsersDto = {
  training_id: string
  user_ids: string[]
}
export type ArchiveTrainingUserDto = {
  user_id: string
}
export type UnassignTrainingUserOutDto = {
  training_user_id: string
}
export type UnassignTrainingUserInDto = {
  training_user_id: string
}
export type BulkUnassignTrainingUsersOutDto = {
  unassigned_training_user_ids: string[]
}
export type BulkUnassignTrainingUsersInDto = {
  training_user_ids: string[]
}
export type BulkUnassignUserResponseDto = {
  unassigned_users: string[]
  skipped_users: string[]
  total_participations_archived: number
}
export type EmailDto = {
  notification_type: string
  language_tag: string
}
export type CertificateDto = {
  id: string
  training_code: string
  training_internal_name: string
  course_title: string
  obtained_on: string
  url: string
}
export const {
  useUsersGetCurrentUserUserGetQuery,
  useUsersGetUsersForTenantTenantsTenantIdUsersGetQuery,
  useUsersCreateUserTenantsTenantIdUsersPostMutation,
  useUsersSearchUsersUsersGetQuery,
  useUsersGetUserTenantsTenantIdUsersUserIdGetQuery,
  useUsersUpdateUserTenantsTenantIdUsersUserIdPutMutation,
  useUsersDeleteUserTenantsTenantIdUsersUserIdDeleteMutation,
  useUsersUpdateUserSettingsTenantsTenantIdUsersUserIdSettingsPatchMutation,
  useUsersArchiveUsersTenantsTenantIdArchiveUsersPutMutation,
  useUsersInviteUsersTenantsTenantIdUsersInvitePostMutation,
  useUsersImportUsersTenantsTenantIdImportUsersPostMutation,
  useUsersUpdateUserStatusTenantsTenantIdUsersUserIdUpdateUserStatusPatchMutation,
  useUsersGetUserFiltersTenantsTenantIdUsersViewFiltersGetQuery,
  useTrainingsGetCurrentUserTrainingParticipationsTenantsTenantIdMeTrainingParticipationsGetQuery,
  useTrainingsAssignUsersToTrainingsTenantsTenantIdTrainingUsersPostMutation,
  useTrainingsGetTrainingUsersTenantsTenantIdTrainingsTrainingCodeUsersGetQuery,
  useTrainingsArchiveTrainingUsersTenantsTenantIdTrainingsTrainingCodeUsersArchivePutMutation,
  useTrainingsUnassignTrainingUserTenantsTenantIdTrainingsTrainingCodeUsersUnassignPutMutation,
  useTrainingsBulkUnassignTrainingUsersTenantsTenantIdTrainingsTrainingCodeUsersBulkUnassignPutMutation,
  useUsersBulkUnassignTrainingsTenantsTenantIdBulkUnassignUsersPutMutation,
  useEmailsSendTrainingEmailsToCurrentUserTenantsTenantIdTrainingsTrainingCodeUsersMeEmailsPostMutation,
  useCertificatesGetCurrentUserCertificatesTenantsTenantIdMeCertificatesGetQuery,
  useGetManagedUsersManagedUsersGetQuery
} = injectedRtkApi
