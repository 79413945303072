import { Route } from '@/core/router.types'
import type Trainings from './Trainings'

/**
 * Trainings page routes.
 */
export default [
  {
    menu: { label: 'myTrainings', icon: 'FavoriteTraining' },
    path: '/my/trainings',
    authorize: ['customer_success', 'customer_manager', 'participant'],
    query: 'trainings',
    showDivider: true,
    dividerStyles: {
      mt: 72,
      mb: 4,
      borderBottom: '1px solid #C4C4E5'
    },
    header: 'myAccount',
    headerStyles: {
      color: '#151830',
      marginLeft: '26px',
      marginTop: '10px',
      marginBottom: '10px'
    },
    component: () => import(/* webpackChunkName: "Trainings" */ /* webpackMode: "eager" */ './Trainings'),
    response: (data) => ({
      title: 'Training Overview',
      description: 'Use the search to start support tasks for trainings.',
      props: data
    })
  } as unknown as Route<typeof Trainings>,
  {
    path: '/my/trainings/:trainingId',
    authorize: ['customer_success', 'customer_manager', 'participant'],
    query: 'trainings',
    component: () => import(/* webpackChunkName: "Trainings" */ /* webpackMode: "eager" */ './Trainings'),
    response: (data) => ({
      title: 'Training Overview',
      description: 'Use the search to start support tasks for trainings.',
      props: data
    })
  } as unknown as Route<typeof Trainings>
] as const
