import React from 'react'
import { FormControl, FormControlLabel, Radio, RadioGroup, Grid } from '@mui/material'

import { RootState, useTypedSelector } from '@/store'
import { useSubscriptionsGetCourseVariantsTenantsTenantIdCoursesCourseIdSubscriptionCourseVariantsGetQuery } from '@/services/api/tenant/courses'
import CustomPalette from '@/theme/CustomPalette'

interface CustomRadioGroupProps {
  defaultValue: string
  courseId: string
  onLanguageSelect: (languageId: string) => void
}

export const CustomRadioGroup: React.FC<CustomRadioGroupProps> = ({ defaultValue, courseId, onLanguageSelect }) => {
  const accessedTenant = useTypedSelector((state: RootState) => state.authSlice.tenant)
  const { data: languages } =
    useSubscriptionsGetCourseVariantsTenantsTenantIdCoursesCourseIdSubscriptionCourseVariantsGetQuery({
      tenantId: accessedTenant?.id,
      courseId: courseId
    })

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedId = event.target.value
    onLanguageSelect(selectedId)
  }

  return (
    <FormControl sx={{ color: '#151830', pl: 4, pb: 2, fontSize: 14, fontWeight: 300 }}>
      <RadioGroup
        aria-labelledby="demo-row-radio-buttons-group-label"
        name="row-radio-buttons-group"
        defaultValue={defaultValue}
        onChange={handleChange}>
        <Grid container spacing={2} ml={-4.5}>
          {[0, 1, 2].map((row) => (
            <Grid container item spacing={2} key={row}>
              {[0, 1, 2].map((col) => (
                <Grid item xs={2} spacing={8} key={col} ml={4}>
                  {languages
                    ? languages.slice(row * 9 + col * 3, row * 9 + (col + 1) * 3).map((language) => (
                        <FormControlLabel
                          key={language.id}
                          value={language.id}
                          control={
                            <Radio
                              size="small"
                              sx={{
                                width: '16px',
                                height: '16px',
                                padding: '8px',
                                paddingRight: '16px',
                                color: CustomPalette.SecondaryNightBlue200,
                                '&.Mui-checked': {
                                  color: CustomPalette.PilotBlue
                                }
                              }}
                            />
                          }
                          label={language.language_display_name}
                          sx={{
                            '& .MuiFormControlLabel-label': {
                              fontSize: '16px',
                              fontWeight: '400',
                              color: CustomPalette.SecondaryNightBlue900,
                              whiteSpace: 'nowrap'
                            }
                          }}
                        />
                      ))
                    : null}
                </Grid>
              ))}
            </Grid>
          ))}
        </Grid>
      </RadioGroup>
    </FormControl>
  )
}
