import CustomPalette from '@/theme/CustomPalette'

export const GridContainerStyle = {
  maxWidth: 'sm',
  marginTop: 0,
  paddingTop: '10px',
  height: '75%',
  '@media (max-width: 1024px)': {
    overflowY: 'auto'
  }
}

export const GridItemStyle = { display: 'block' }

export const AppBarHeaderStyle = {
  position: 'fixed',
  backgroundColor: '#F8F8FA',
  boxShadow: 0,
  '@media (max-width: 1024px)': {
    '.MuiToolbar-root': {
      justifyContent: 'center'
    }
  }
}

export const IconButtonStyle = { maxWidth: 230, mt: 2, ml: 5, flex: 1, ':hover': { bgcolor: '#F8F8FA' } }

export const BoxStyle = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '100vmax',
  backgroundColor: '#F8F8FA',
  pl: 19
}

export const WelcomeTypographyGridItemStyle = {
  marginTop: '-28px'
}

export const WelcomeTypographyStyle = {
  color: '#151830',
  fontSize: '36px',
  pt: -1,
  pl: '60px',
  marginLeft: -4,
  fontFamily: 'BeausiteFit-Medium',
  width: '100%',
  '@media (max-width: 1024px)': {
    fontSize: '31px'
  }
}

export const DescriptionTypographyStyle = {
  maxWidth: 410,
  color: '#151830',
  pt: -2,
  pl: 4,
  whiteSpace: 'pre-line',
  lineHeight: 1.5
}

export const TitleTypographyStyle = { color: CustomPalette.PilotBlue, mt: -1, pl: 4, fontWeight: 700 }

export const CourseSelectTypographyStyle = { color: '#151830', mt: 1, pl: 4, fontWeight: 400 }

export const ContinueButtonStyle = (color: string | undefined) => ({
  borderRadius: '20px 20px 20px 20px',
  borderColor: color ? color : CustomPalette.NeutralColor,
  backgroundColor: color ? color : CustomPalette.NeutralColor,
  color: '#FFFFFF',
  fontSize: 14,
  marginLeft: '33px',
  ':hover': {
    bgcolor: color ? color : CustomPalette.NeutralColor,
    borderColor: color ? color : CustomPalette.NeutralColor,
    color: 'white'
  },
  '@media (max-width: 1024px)': {
    width: '400px',
    margin: '25px 50px 0 50px'
  },
  '@media (min-width: 1025px)': {
    width: 'initial'
  }
})

export const AppBarFooterStyle = { boxShadow: 0, backgroundColor: '#F8F8FA', top: 'auto', bottom: 0 }

export const BackButtonStyle = {
  color: '#463ED7',
  fontWeight: 400,
  fontSize: '16px',
  lineHeight: '24px',
  ml: 8,
  '@media (min-width: 1025px)': {
    position: 'fixed',
    left: '30px',
    bottom: '14px'
  },
  '@media (max-width: 1024px)': {
    left: '87px',
    top: '15px'
  },
  ':hover': {
    backgroundColor: CustomPalette.SecondaryNightBlue50,
    borderRadius: '20px'
  }
}

export const ImprintButtonGridItemStyle = {
  '@media (min-width: 1025px)': { marginTop: '-30px' }
}

export const ImprintButtonStyle = {
  color: '#463ED7',
  fontWeight: 400,
  fontSize: '16px',
  lineHeight: '24px',
  marginLeft: '33px',
  textDecoration: 'underline',
  ':hover': {
    backgroundColor: CustomPalette.SecondaryNightBlue50,
    borderRadius: '20px'
  }
}

export const PageLanguageGridItemStyle = {
  '@media (max-width: 1024px)': { marginTop: '18px' }
}

export const PageLanguageStackStyle = {
  marginLeft: '33px',
  alignItems: 'baseline',
  '@media (max-width: 1024px)': { marginLeft: '114px' }
}

export const PageLanguageTypographyStyle = {
  color: '#5B5C78',
  fontWeight: 400,
  fontSize: '16px',
  lineHeight: '24px',
  pt: 0.7,
  ml: 25
}
