import { tenantApi as api } from './api'
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    trainingsGetTrainingsTenantsTenantIdTrainingsGet: build.query<
      TrainingsGetTrainingsTenantsTenantIdTrainingsGetApiResponse,
      TrainingsGetTrainingsTenantsTenantIdTrainingsGetApiArg
    >({
      query: (queryArg) => ({ url: `/tenants/${queryArg.tenantId}/trainings` })
    }),
    trainingsCreateTrainingTenantsTenantIdTrainingsPost: build.mutation<
      TrainingsCreateTrainingTenantsTenantIdTrainingsPostApiResponse,
      TrainingsCreateTrainingTenantsTenantIdTrainingsPostApiArg
    >({
      query: (queryArg) => ({
        url: `/tenants/${queryArg.tenantId}/trainings`,
        method: 'POST',
        body: queryArg.createTrainingDto
      })
    }),
    trainingsGetMyTrainingsTenantsTenantIdMeTrainingsGet: build.query<
      TrainingsGetMyTrainingsTenantsTenantIdMeTrainingsGetApiResponse,
      TrainingsGetMyTrainingsTenantsTenantIdMeTrainingsGetApiArg
    >({
      query: (queryArg) => ({ url: `/tenants/${queryArg.tenantId}/me/trainings` })
    }),
    trainingsGetTrainingByCodeTenantsTenantIdTrainingsTrainingCodeGet: build.query<
      TrainingsGetTrainingByCodeTenantsTenantIdTrainingsTrainingCodeGetApiResponse,
      TrainingsGetTrainingByCodeTenantsTenantIdTrainingsTrainingCodeGetApiArg
    >({
      query: (queryArg) => ({ url: `/tenants/${queryArg.tenantId}/trainings/${queryArg.trainingCode}` })
    }),
    trainingsArchiveTrainingTenantsTenantIdTrainingsTrainingCodeArchivePut: build.mutation<
      TrainingsArchiveTrainingTenantsTenantIdTrainingsTrainingCodeArchivePutApiResponse,
      TrainingsArchiveTrainingTenantsTenantIdTrainingsTrainingCodeArchivePutApiArg
    >({
      query: (queryArg) => ({
        url: `/tenants/${queryArg.tenantId}/trainings/${queryArg.trainingCode}/archive`,
        method: 'PUT'
      })
    }),
    trainingsGetTrainingDetailsByCodeTenantsTenantIdTrainingsTrainingCodeDetailsGet: build.query<
      TrainingsGetTrainingDetailsByCodeTenantsTenantIdTrainingsTrainingCodeDetailsGetApiResponse,
      TrainingsGetTrainingDetailsByCodeTenantsTenantIdTrainingsTrainingCodeDetailsGetApiArg
    >({
      query: (queryArg) => ({ url: `/tenants/${queryArg.tenantId}/trainings/${queryArg.trainingCode}/details` })
    }),
    trainingsGetTrainingParticipationsTenantsTenantIdTrainingsTrainingCodeParticipationsGet: build.query<
      TrainingsGetTrainingParticipationsTenantsTenantIdTrainingsTrainingCodeParticipationsGetApiResponse,
      TrainingsGetTrainingParticipationsTenantsTenantIdTrainingsTrainingCodeParticipationsGetApiArg
    >({
      query: (queryArg) => ({
        url: `/tenants/${queryArg.tenantId}/trainings/${queryArg.trainingCode}/participations`,
        params: {
          training_cycle_id: queryArg.trainingCycleId,
          only_completed: queryArg.onlyCompleted,
          page: queryArg.page,
          size: queryArg.size
        }
      })
    }),
    trainingsStartTenantsTenantIdTrainingsTrainingCodeTrainingCyclesTrainingCycleIdStartPost: build.mutation<
      TrainingsStartTenantsTenantIdTrainingsTrainingCodeTrainingCyclesTrainingCycleIdStartPostApiResponse,
      TrainingsStartTenantsTenantIdTrainingsTrainingCodeTrainingCyclesTrainingCycleIdStartPostApiArg
    >({
      query: (queryArg) => ({
        url: `/tenants/${queryArg.tenantId}/trainings/${queryArg.trainingCode}/training_cycles/${queryArg.trainingCycleId}/start`,
        method: 'POST',
        body: queryArg.trainingStartInDto
      })
    }),
    trainingsExportTrainingParticipationsTenantsTenantIdTrainingsTrainingCodeParticipationsExportPost: build.mutation<
      TrainingsExportTrainingParticipationsTenantsTenantIdTrainingsTrainingCodeParticipationsExportPostApiResponse,
      TrainingsExportTrainingParticipationsTenantsTenantIdTrainingsTrainingCodeParticipationsExportPostApiArg
    >({
      query: (queryArg) => ({
        url: `/tenants/${queryArg.tenantId}/trainings/${queryArg.trainingCode}/participations/export`,
        method: 'POST',
        body: queryArg.trainingParticipationsExportInDto
      })
    }),
    trainingsGetCurrentUserTrainingParticipationsTenantsTenantIdMeTrainingParticipationsGet: build.query<
      TrainingsGetCurrentUserTrainingParticipationsTenantsTenantIdMeTrainingParticipationsGetApiResponse,
      TrainingsGetCurrentUserTrainingParticipationsTenantsTenantIdMeTrainingParticipationsGetApiArg
    >({
      query: (queryArg) => ({ url: `/tenants/${queryArg.tenantId}/me/training_participations` })
    }),
    trainingsRetryCertificateGenerationTenantsTenantIdTrainingParticipationsTrainingParticipationIdRetryCertGenerationPost:
      build.mutation<
        TrainingsRetryCertificateGenerationTenantsTenantIdTrainingParticipationsTrainingParticipationIdRetryCertGenerationPostApiResponse,
        TrainingsRetryCertificateGenerationTenantsTenantIdTrainingParticipationsTrainingParticipationIdRetryCertGenerationPostApiArg
      >({
        query: (queryArg) => ({
          url: `/tenants/${queryArg.tenantId}/training_participations/${queryArg.trainingParticipationId}/retry_cert_generation`,
          method: 'POST'
        })
      }),
    trainingsAssignUsersToTrainingsTenantsTenantIdTrainingUsersPost: build.mutation<
      TrainingsAssignUsersToTrainingsTenantsTenantIdTrainingUsersPostApiResponse,
      TrainingsAssignUsersToTrainingsTenantsTenantIdTrainingUsersPostApiArg
    >({
      query: (queryArg) => ({
        url: `/tenants/${queryArg.tenantId}/training_users`,
        method: 'POST',
        body: queryArg.payloads,
        params: { send_invite: queryArg.sendInvite }
      })
    }),
    trainingsGetTrainingUsersTenantsTenantIdTrainingsTrainingCodeUsersGet: build.query<
      TrainingsGetTrainingUsersTenantsTenantIdTrainingsTrainingCodeUsersGetApiResponse,
      TrainingsGetTrainingUsersTenantsTenantIdTrainingsTrainingCodeUsersGetApiArg
    >({
      query: (queryArg) => ({
        url: `/tenants/${queryArg.tenantId}/trainings/${queryArg.trainingCode}/users`,
        params: { only_completed: queryArg.onlyCompleted, training_cycle_id: queryArg.trainingCycleId }
      })
    }),
    trainingsArchiveTrainingUsersTenantsTenantIdTrainingsTrainingCodeUsersArchivePut: build.mutation<
      TrainingsArchiveTrainingUsersTenantsTenantIdTrainingsTrainingCodeUsersArchivePutApiResponse,
      TrainingsArchiveTrainingUsersTenantsTenantIdTrainingsTrainingCodeUsersArchivePutApiArg
    >({
      query: (queryArg) => ({
        url: `/tenants/${queryArg.tenantId}/trainings/${queryArg.trainingCode}/users/archive`,
        method: 'PUT'
      })
    }),
    trainingsUnassignTrainingUserTenantsTenantIdTrainingsTrainingCodeUsersUnassignPut: build.mutation<
      TrainingsUnassignTrainingUserTenantsTenantIdTrainingsTrainingCodeUsersUnassignPutApiResponse,
      TrainingsUnassignTrainingUserTenantsTenantIdTrainingsTrainingCodeUsersUnassignPutApiArg
    >({
      query: (queryArg) => ({
        url: `/tenants/${queryArg.tenantId}/trainings/${queryArg.trainingCode}/users/unassign`,
        method: 'PUT',
        body: queryArg.unassignTrainingUserInDto
      })
    }),
    trainingsBulkUnassignTrainingUsersTenantsTenantIdTrainingsTrainingCodeUsersBulkUnassignPut: build.mutation<
      TrainingsBulkUnassignTrainingUsersTenantsTenantIdTrainingsTrainingCodeUsersBulkUnassignPutApiResponse,
      TrainingsBulkUnassignTrainingUsersTenantsTenantIdTrainingsTrainingCodeUsersBulkUnassignPutApiArg
    >({
      query: (queryArg) => ({
        url: `/tenants/${queryArg.tenantId}/trainings/${queryArg.trainingCode}/users/bulk-unassign`,
        method: 'PUT',
        body: queryArg.bulkUnassignTrainingUsersInDto
      })
    }),
    usersBulkUnassignTrainingsTenantsTenantIdBulkUnassignUsersPut: build.mutation<
      UsersBulkUnassignTrainingsTenantsTenantIdBulkUnassignUsersPutApiResponse,
      UsersBulkUnassignTrainingsTenantsTenantIdBulkUnassignUsersPutApiArg
    >({
      query: (queryArg) => ({
        url: `/tenants/${queryArg.tenantId}/bulk-unassign/users`,
        method: 'PUT',
        body: queryArg.userData
      })
    }),
    trainingsGetTrainingCyclesTenantsTenantIdTrainingsTrainingCodeTrainingCyclesGet: build.query<
      TrainingsGetTrainingCyclesTenantsTenantIdTrainingsTrainingCodeTrainingCyclesGetApiResponse,
      TrainingsGetTrainingCyclesTenantsTenantIdTrainingsTrainingCodeTrainingCyclesGetApiArg
    >({
      query: (queryArg) => ({ url: `/tenants/${queryArg.tenantId}/trainings/${queryArg.trainingCode}/training_cycles` })
    }),
    trainingsGetTrainingCycleTenantsTenantIdTrainingsTrainingCodeTrainingCyclesTrainingCycleIdGet: build.query<
      TrainingsGetTrainingCycleTenantsTenantIdTrainingsTrainingCodeTrainingCyclesTrainingCycleIdGetApiResponse,
      TrainingsGetTrainingCycleTenantsTenantIdTrainingsTrainingCodeTrainingCyclesTrainingCycleIdGetApiArg
    >({
      query: (queryArg) => ({
        url: `/tenants/${queryArg.tenantId}/trainings/${queryArg.trainingCode}/training_cycles/${queryArg.trainingCycleId}`
      })
    }),
    emailsSendTrainingEmailsTenantsTenantIdTrainingsTrainingCodeEmailsPost: build.mutation<
      EmailsSendTrainingEmailsTenantsTenantIdTrainingsTrainingCodeEmailsPostApiResponse,
      EmailsSendTrainingEmailsTenantsTenantIdTrainingsTrainingCodeEmailsPostApiArg
    >({
      query: (queryArg) => ({
        url: `/tenants/${queryArg.tenantId}/trainings/${queryArg.trainingCode}/emails`,
        method: 'POST',
        body: queryArg.usersEmailDto
      })
    }),
    emailsSendTrainingEmailsToCurrentUserTenantsTenantIdTrainingsTrainingCodeUsersMeEmailsPost: build.mutation<
      EmailsSendTrainingEmailsToCurrentUserTenantsTenantIdTrainingsTrainingCodeUsersMeEmailsPostApiResponse,
      EmailsSendTrainingEmailsToCurrentUserTenantsTenantIdTrainingsTrainingCodeUsersMeEmailsPostApiArg
    >({
      query: (queryArg) => ({
        url: `/tenants/${queryArg.tenantId}/trainings/${queryArg.trainingCode}/users/me/emails`,
        method: 'POST',
        body: queryArg.emailDto
      })
    }),
    certificatesDownloadZippedCertificatesTenantsTenantIdTrainingsTrainingCodeTrainingCyclesTrainingCycleIdZippedCertificatesGet:
      build.query<
        CertificatesDownloadZippedCertificatesTenantsTenantIdTrainingsTrainingCodeTrainingCyclesTrainingCycleIdZippedCertificatesGetApiResponse,
        CertificatesDownloadZippedCertificatesTenantsTenantIdTrainingsTrainingCodeTrainingCyclesTrainingCycleIdZippedCertificatesGetApiArg
      >({
        query: (queryArg) => ({
          url: `/tenants/${queryArg.tenantId}/trainings/${queryArg.trainingCode}/training_cycles/${queryArg.trainingCycleId}/zipped-certificates`
        })
      })
  }),
  overrideExisting: true
})
export { injectedRtkApi as enhancedApi }
export type TrainingsGetTrainingsTenantsTenantIdTrainingsGetApiResponse =
  /** status 200 Successful Response */ TrainingWithTotalStatisticsDto[]
export type TrainingsGetTrainingsTenantsTenantIdTrainingsGetApiArg = {
  tenantId: string
}
export type TrainingsCreateTrainingTenantsTenantIdTrainingsPostApiResponse =
  /** status 201 Successful Response */ TrainingDto
export type TrainingsCreateTrainingTenantsTenantIdTrainingsPostApiArg = {
  tenantId: string
  createTrainingDto: CreateTrainingDto
}
export type TrainingsGetMyTrainingsTenantsTenantIdMeTrainingsGetApiResponse =
  /** status 200 Successful Response */ TrainingDto[]
export type TrainingsGetMyTrainingsTenantsTenantIdMeTrainingsGetApiArg = {
  tenantId: string
}
export type TrainingsGetTrainingByCodeTenantsTenantIdTrainingsTrainingCodeGetApiResponse =
  /** status 200 Successful Response */ TrainingDto
export type TrainingsGetTrainingByCodeTenantsTenantIdTrainingsTrainingCodeGetApiArg = {
  tenantId: string
  trainingCode: string
}
export type TrainingsArchiveTrainingTenantsTenantIdTrainingsTrainingCodeArchivePutApiResponse =
  /** status 200 Successful Response */ void
export type TrainingsArchiveTrainingTenantsTenantIdTrainingsTrainingCodeArchivePutApiArg = {
  tenantId: string
  trainingCode: string
}
export type TrainingsGetTrainingDetailsByCodeTenantsTenantIdTrainingsTrainingCodeDetailsGetApiResponse =
  /** status 200 Successful Response */ TrainingDetailsDto
export type TrainingsGetTrainingDetailsByCodeTenantsTenantIdTrainingsTrainingCodeDetailsGetApiArg = {
  tenantId: string
  trainingCode: string
}
export type TrainingsGetTrainingParticipationsTenantsTenantIdTrainingsTrainingCodeParticipationsGetApiResponse =
  /** status 200 Successful Response */ PageTrainingParticipationDto | Page
export type TrainingsGetTrainingParticipationsTenantsTenantIdTrainingsTrainingCodeParticipationsGetApiArg = {
  tenantId: string
  trainingCode: string
  /** UUID of the training cycle. Defaults to "current" training cycle if not specified. */
  trainingCycleId?: string
  /** If set to `true`, the endpoint will return only completed participations. Defaults to `false`. */
  onlyCompleted?: boolean
  page?: number
  size?: number
}
export type TrainingsStartTenantsTenantIdTrainingsTrainingCodeTrainingCyclesTrainingCycleIdStartPostApiResponse =
  /** status 200 Successful Response */ TrainingStartOutDto
export type TrainingsStartTenantsTenantIdTrainingsTrainingCodeTrainingCyclesTrainingCycleIdStartPostApiArg = {
  tenantId: string
  trainingCycleId: string
  trainingCode: string
  trainingStartInDto: TrainingStartInDto
}
export type TrainingsExportTrainingParticipationsTenantsTenantIdTrainingsTrainingCodeParticipationsExportPostApiResponse =
  /** status 200 Successful Response */ TrainingParticipationsExportOutDto
export type TrainingsExportTrainingParticipationsTenantsTenantIdTrainingsTrainingCodeParticipationsExportPostApiArg = {
  tenantId: string
  trainingCode: string
  trainingParticipationsExportInDto: TrainingParticipationsExportInDto
}
export type TrainingsGetCurrentUserTrainingParticipationsTenantsTenantIdMeTrainingParticipationsGetApiResponse =
  /** status 200 Successful Response */ CurrentUserTrainingParticipationDto[]
export type TrainingsGetCurrentUserTrainingParticipationsTenantsTenantIdMeTrainingParticipationsGetApiArg = {
  tenantId: string
}
export type TrainingsRetryCertificateGenerationTenantsTenantIdTrainingParticipationsTrainingParticipationIdRetryCertGenerationPostApiResponse =
  /** status 200 Successful Response */ boolean
export type TrainingsRetryCertificateGenerationTenantsTenantIdTrainingParticipationsTrainingParticipationIdRetryCertGenerationPostApiArg =
  {
    tenantId: string
    trainingParticipationId: string
  }
export type TrainingsAssignUsersToTrainingsTenantsTenantIdTrainingUsersPostApiResponse =
  /** status 200 Successful Response */ GroupedTrainingUsersDto[]
export type TrainingsAssignUsersToTrainingsTenantsTenantIdTrainingUsersPostApiArg = {
  tenantId: string
  /** Toggles sending training invitation emails */
  sendInvite?: boolean
  payloads: CreateTrainingUsersDto[]
}
export type TrainingsGetTrainingUsersTenantsTenantIdTrainingsTrainingCodeUsersGetApiResponse =
  /** status 200 Successful Response */ TrainingUserDto[]
export type TrainingsGetTrainingUsersTenantsTenantIdTrainingsTrainingCodeUsersGetApiArg = {
  tenantId: string
  trainingCode: string
  onlyCompleted?: boolean
  trainingCycleId?: string
}
export type TrainingsArchiveTrainingUsersTenantsTenantIdTrainingsTrainingCodeUsersArchivePutApiResponse =
  /** status 200 Successful Response */ ArchiveTrainingUserDto[]
export type TrainingsArchiveTrainingUsersTenantsTenantIdTrainingsTrainingCodeUsersArchivePutApiArg = {
  tenantId: string
  trainingCode: string
}
export type TrainingsUnassignTrainingUserTenantsTenantIdTrainingsTrainingCodeUsersUnassignPutApiResponse =
  /** status 200 Successful Response */ UnassignTrainingUserOutDto
export type TrainingsUnassignTrainingUserTenantsTenantIdTrainingsTrainingCodeUsersUnassignPutApiArg = {
  tenantId: string
  trainingCode: string
  unassignTrainingUserInDto: UnassignTrainingUserInDto
}
export type TrainingsBulkUnassignTrainingUsersTenantsTenantIdTrainingsTrainingCodeUsersBulkUnassignPutApiResponse =
  /** status 200 Successful Response */ BulkUnassignTrainingUsersOutDto
export type TrainingsBulkUnassignTrainingUsersTenantsTenantIdTrainingsTrainingCodeUsersBulkUnassignPutApiArg = {
  tenantId: string
  trainingCode: string
  bulkUnassignTrainingUsersInDto: BulkUnassignTrainingUsersInDto
}
export type UsersBulkUnassignTrainingsTenantsTenantIdBulkUnassignUsersPutApiResponse =
  /** status 200 Successful Response */ BulkUnassignUserResponseDto
export type UsersBulkUnassignTrainingsTenantsTenantIdBulkUnassignUsersPutApiArg = {
  tenantId: string
  userData: {
    [key: string]: string[]
  }
}
export type TrainingsGetTrainingCyclesTenantsTenantIdTrainingsTrainingCodeTrainingCyclesGetApiResponse =
  /** status 200 Successful Response */ TrainingCycleDto[]
export type TrainingsGetTrainingCyclesTenantsTenantIdTrainingsTrainingCodeTrainingCyclesGetApiArg = {
  tenantId: string
  trainingCode: string
}
export type TrainingsGetTrainingCycleTenantsTenantIdTrainingsTrainingCodeTrainingCyclesTrainingCycleIdGetApiResponse =
  /** status 200 Successful Response */ TrainingCycleDto
export type TrainingsGetTrainingCycleTenantsTenantIdTrainingsTrainingCodeTrainingCyclesTrainingCycleIdGetApiArg = {
  trainingCycleId: string
  tenantId: string
  trainingCode: string
}
export type EmailsSendTrainingEmailsTenantsTenantIdTrainingsTrainingCodeEmailsPostApiResponse =
  /** status 200 Successful Response */ any
export type EmailsSendTrainingEmailsTenantsTenantIdTrainingsTrainingCodeEmailsPostApiArg = {
  tenantId: string
  trainingCode: string
  usersEmailDto: UsersEmailDto
}
export type EmailsSendTrainingEmailsToCurrentUserTenantsTenantIdTrainingsTrainingCodeUsersMeEmailsPostApiResponse =
  /** status 200 Successful Response */ any
export type EmailsSendTrainingEmailsToCurrentUserTenantsTenantIdTrainingsTrainingCodeUsersMeEmailsPostApiArg = {
  tenantId: string
  trainingCode: string
  emailDto: EmailDto
}
export type CertificatesDownloadZippedCertificatesTenantsTenantIdTrainingsTrainingCodeTrainingCyclesTrainingCycleIdZippedCertificatesGetApiResponse =
  /** status 200 Successful Response */ DownloadCertificateDto
export type CertificatesDownloadZippedCertificatesTenantsTenantIdTrainingsTrainingCodeTrainingCyclesTrainingCycleIdZippedCertificatesGetApiArg =
  {
    trainingCycleId: string
    tenantId: string
    trainingCode: string
  }
export type TrainingStatus = 'active' | 'inactive'
export type TrainingReminderType = 'automated' | 'never'
export type TrainingReminderModel = {
  type?: TrainingReminderType
  schedule_in_days?: number
  last_sent_at?: string
}
export type TrainingWithTotalStatisticsDto = {
  id: string
  tenant_id: string
  course_id: string
  subscription_id: string
  code: string
  internal_name: string
  course_title?: string
  start_date: string
  end_date?: string
  due_date_in_weeks: number
  recurring_interval_in_months: number
  status: TrainingStatus
  reminder: TrainingReminderModel
  duration_in_minutes: number
  created_at?: string
  updated_at?: string
  archived_at?: string
  total_participations: number
  completion_percentage: number
}
export type ValidationError = {
  loc: (string | number)[]
  msg: string
  type: string
}
export type HttpValidationError = {
  detail?: ValidationError[]
}
export type TrainingDto = {
  id: string
  tenant_id: string
  course_id: string
  subscription_id: string
  code: string
  internal_name: string
  course_title?: string
  start_date: string
  end_date?: string
  due_date_in_weeks: number
  recurring_interval_in_months: number
  status: TrainingStatus
  reminder: TrainingReminderModel
  duration_in_minutes: number
  created_at?: string
  updated_at?: string
  archived_at?: string
}
export type CreateTrainingDto = {
  course_id: string
  subscription_id: string
  internal_name: string
  due_date_in_weeks: number
  reminder: TrainingReminderModel
}
export type TrainingDetailsTrainingDto = {
  id: string
  course_id: string
  code: string
  internal_name: string
  course_title?: string
}
export type TrainingDetailsTrainingCycleDto = {
  id: string
  start_date: string
  end_date: string
}
export type TrainingDetailsStatisticsDto = {
  total_participations: number
  completed_participations: number
  completion_percentage: number
}
export type TrainingDetailsLicensesDto = {
  total: number
  used: number
}
export type TrainingDetailsDto = {
  training: TrainingDetailsTrainingDto
  current_training_cycle: TrainingDetailsTrainingCycleDto
  training_cycles: TrainingDetailsTrainingCycleDto[]
  statistics: TrainingDetailsStatisticsDto
  licenses: TrainingDetailsLicensesDto
}
export type TrainingParticipationStatus = 'invited' | 'in_progress' | 'completed'
export type TrainingParticipantStatus = 'active' | 'inactive'
export type FormOfAddress = 'formal' | 'informal'
export type TrainingParticipationDto = {
  id: string
  name: string
  email: string
  employee_id?: string
  department?: string
  location?: string
  position?: string
  training_status?: TrainingParticipationStatus
  last_contact?: string
  repeat?: string
  status?: TrainingParticipantStatus
  language_tag?: string
  form_of_address?: FormOfAddress
  training_user_id: string
  certificate_url?: string
  completed_at?: string
}
export type PageTrainingParticipationDto = {
  items: TrainingParticipationDto[]
  total?: number
  page?: number
  size?: number
  pages?: number
}
export type Page = {
  items: any[]
  total?: number
  page?: number
  size?: number
  pages?: number
}
export type TrainingStartOutDto = {
  courseplay_url: string
}
export type TrainingStartInDto = {
  course_variant_id: string
}
export type TrainingParticipationsExportOutDto = {
  success: boolean
  url?: string
}
export type TrainingParticipationsExportInDto = {
  training_cycle_id?: string
  only_completed?: boolean
  language_tag?: string
}
export type CurrentUserTrainingParticipationDto = {
  id: string
  training_code: string
  training_internal_name: string
  course_title: string
  training_start_date: string
  training_end_date?: string
  training_recurring_interval: number
  training_description: string
  training_status?: TrainingParticipationStatus
  repeat: string
  training_user_id: string
  training_cycle_id: string
  course_id: string
  course_duration_in_minutes: number
  courseplay_url?: string
}
export type TrainingUserDto = {
  id: string
  tenant_id: string
  training_id: string
  user_id: string
  completed_on?: string
  created_at?: string
  updated_at?: string
  archived_at?: string
}
export type GroupedTrainingUsersDto = {
  tenant_id: string
  training_id: string
  users: (TrainingUserDto | string)[]
  errors?: string[]
  is_successful: boolean
}
export type CreateTrainingUsersDto = {
  training_id: string
  user_ids: string[]
}
export type ArchiveTrainingUserDto = {
  user_id: string
}
export type UnassignTrainingUserOutDto = {
  training_user_id: string
}
export type UnassignTrainingUserInDto = {
  training_user_id: string
}
export type BulkUnassignTrainingUsersOutDto = {
  unassigned_training_user_ids: string[]
}
export type BulkUnassignTrainingUsersInDto = {
  training_user_ids: string[]
}
export type BulkUnassignUserResponseDto = {
  unassigned_users: string[]
  skipped_users: string[]
  total_participations_archived: number
}
export type TrainingCycleDto = {
  id: string
  training_id: string
  sequence_number: number
  start_date: string
  end_date: string
  due_date?: string
  created_at: string
  updated_at: string
  archived_at?: string
}
export type UsersEmailDto = {
  notification_type: string
  language_tag: string
  user_ids: string[]
}
export type EmailDto = {
  notification_type: string
  language_tag: string
}
export type DownloadCertificateDto = {
  url: string
}
export const {
  usePrefetch,
  useTrainingsGetTrainingsTenantsTenantIdTrainingsGetQuery,
  useTrainingsCreateTrainingTenantsTenantIdTrainingsPostMutation,
  useTrainingsGetMyTrainingsTenantsTenantIdMeTrainingsGetQuery,
  useTrainingsGetTrainingByCodeTenantsTenantIdTrainingsTrainingCodeGetQuery,
  useTrainingsArchiveTrainingTenantsTenantIdTrainingsTrainingCodeArchivePutMutation,
  useTrainingsGetTrainingDetailsByCodeTenantsTenantIdTrainingsTrainingCodeDetailsGetQuery,
  useTrainingsGetTrainingParticipationsTenantsTenantIdTrainingsTrainingCodeParticipationsGetQuery,
  useTrainingsStartTenantsTenantIdTrainingsTrainingCodeTrainingCyclesTrainingCycleIdStartPostMutation,
  useTrainingsExportTrainingParticipationsTenantsTenantIdTrainingsTrainingCodeParticipationsExportPostMutation,
  useTrainingsGetCurrentUserTrainingParticipationsTenantsTenantIdMeTrainingParticipationsGetQuery,
  useTrainingsRetryCertificateGenerationTenantsTenantIdTrainingParticipationsTrainingParticipationIdRetryCertGenerationPostMutation,
  useTrainingsAssignUsersToTrainingsTenantsTenantIdTrainingUsersPostMutation,
  useTrainingsGetTrainingUsersTenantsTenantIdTrainingsTrainingCodeUsersGetQuery,
  useTrainingsArchiveTrainingUsersTenantsTenantIdTrainingsTrainingCodeUsersArchivePutMutation,
  useTrainingsUnassignTrainingUserTenantsTenantIdTrainingsTrainingCodeUsersUnassignPutMutation,
  useTrainingsBulkUnassignTrainingUsersTenantsTenantIdTrainingsTrainingCodeUsersBulkUnassignPutMutation,
  useUsersBulkUnassignTrainingsTenantsTenantIdBulkUnassignUsersPutMutation,
  useTrainingsGetTrainingCyclesTenantsTenantIdTrainingsTrainingCodeTrainingCyclesGetQuery,
  useTrainingsGetTrainingCycleTenantsTenantIdTrainingsTrainingCodeTrainingCyclesTrainingCycleIdGetQuery,
  useEmailsSendTrainingEmailsTenantsTenantIdTrainingsTrainingCodeEmailsPostMutation,
  useEmailsSendTrainingEmailsToCurrentUserTenantsTenantIdTrainingsTrainingCodeUsersMeEmailsPostMutation,
  useCertificatesDownloadZippedCertificatesTenantsTenantIdTrainingsTrainingCodeTrainingCyclesTrainingCycleIdZippedCertificatesGetQuery
} = injectedRtkApi
