import { AppBarProps, Box } from '@mui/material'
import { styled } from '@mui/material/styles'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { ProductFruits } from 'react-product-fruits'

import { AppToolBar, FullScreenProgress, Main, SideBar } from '../../common/components'
import { LoggedOutModal, useAuthUser } from '../../features/auth'

type LayoutProps = AppBarProps & {
  children: React.ReactNode
  onChangeTheme: () => void
}

const LayoutRoot = styled('div')(() => ({
  display: 'flex',
  flex: '1 1 auto',
  maxWidth: '100%',
  paddingTop: 64
}))

export const Layout = (props: LayoutProps): JSX.Element => {
  const { onChangeTheme, children } = props
  const [open, setOpen] = React.useState(true)
  const user = useAuthUser()
  const rootUrl = new URL(document?.URL)

  if (!user.token) {
    // Rendering placeholder page here while the user agent is redirected to
    // login.
    return <FullScreenProgress />
  }

  const domain = rootUrl.hostname.replace('www.', '')
  const subDomain = domain.split('.')[0]
  const handleDrawerToggle = () => {
    setOpen(!open)
  }
  const userInfo = {
    username: user.username,
    role: user.roles[0],
    group: {
      groupId: subDomain + user.roles[0]
    }
  }
  const { i18n } = useTranslation('general')
  const [languageCode, setLanguageCode] = React.useState(i18n.language.split('-')[0])
  React.useEffect(() => {
    setLanguageCode(i18n.language.split('-')[0])
  }, [i18n.language])

  const handleForceOpen = () => {
    setOpen(true)
  }

  return (
    <LayoutRoot>
      <ProductFruits workspaceCode="hqvCOgDUYr5cOK8F" language={languageCode} user={userInfo} />
      <AppToolBar isOpen={open} onDrawerToggle={handleDrawerToggle} onChangeTheme={onChangeTheme} />
      <SideBar isOpen={open} onDrawerToggle={handleDrawerToggle} handleForceOpen={handleForceOpen}></SideBar>
      <Box sx={{ display: 'flex', flex: '1 1 auto', flexDirection: 'column', width: '100%', backgroundColor: '#FFF' }}>
        <Main>{children}</Main>
      </Box>
      <LoggedOutModal />
    </LayoutRoot>
  )
}
